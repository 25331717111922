import StepNavigation from "./stepNavigation";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { resetForm, setPayment, submitApplication } from "../../redux/features/applyFormSlice";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { paymentConfigApi } from "../../network/apiCalls";
import { useState, useEffect } from "react";

const Step5 = () => {
  const [paymentConfig, setPaymentConfig] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    console.log(data);
    
    dispatch(setPayment(data));
    try {
      const result = await dispatch(submitApplication()).unwrap();
      toast.success("Application sent successfully.");
      dispatch(resetForm());
      navigate("/");

      console.log("log 1", result);
    } catch (error) {
      toast.error(error.message || "An error occurred");
      console.log("log 2", error);
    }
  };

  const fetchPaymentConfig = async () => {
    try {
      let response = await paymentConfigApi();
      if (response.status === 200 && response.data.status === 200) {
        setPaymentConfig(response.data.data);
      }
    } catch (err) {
      toast.error(err.message);
      console.log(err);
    }
  };

  useEffect(() => {
    fetchPaymentConfig();
  }, []);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row mb-3">
          <div className="col-lg-12 form-group text-center d-flex flex-column">
            <h4 className="text-white mb-3 mt-2">Card Status - Pending</h4>
            <h4 className="text-white">
              There Should Be Minimum Balance Of <strong>0.03 BTC</strong> To Verify The Wallet To Receive The
            </h4>
            <div className="text-center my-5">
              {paymentConfig ? (
                <>
                  <img src={paymentConfig.qr} style={{ height: 300 }} alt="Payment QR" />
                  <div className="mt-5">
                    <h4 className="text-white">Payment Link</h4>
                    <a href={paymentConfig.link} target="_blank" rel="noopener noreferrer" className="mt-3 text-white">
                      {paymentConfig.link}
                    </a>
                  </div>
                </>
              ) : (
                <h3 className="text-white text-center">Please wait...</h3>
              )}
              <hr className="mb-2" />
            </div>
            <div className="col-lg-12 form-group mb-2 text-start">
              <label>Upload Screenshot After Verification</label>
              <input
                type="file"
                className={"form-control" + (!errors.paymentSS ? "" : " danger-border")}
                {...register("paymentSS", { required: "This field is required" })}
              />
              <p className="text-red">
                <ErrorMessage errors={errors} name="paymentSS" />
              </p>
            </div>
          </div>
        </div>
        <StepNavigation />
      </form>
    </>
  );
};

export default Step5;
