import StepNavigation from "./stepNavigation";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { nextTab, setDocuments } from "../../redux/features/applyFormSlice";
import { useDispatch } from "react-redux";

const Step2 = () => {
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    // console.log("step 2", data);
    dispatch(setDocuments(data));
    dispatch(nextTab());
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row mb-3">
          <div className="col-lg-6 form-group">
            <label>Tax ID Detail</label>
            <input
              type="file"
              className={
                "form-control" + (!errors.taxid ? "" : " danger-border")
              }
              {...register("taxid")}
              accept="application/pdf, image/jpg,image/jpeg,image/png"
            />
            <p className="text-red">
              <ErrorMessage errors={errors} name="taxid" />
            </p>
          </div>
          <div className="col-lg-6 form-group">
            <label>Government Issued Id Front (Not driving license)</label>
            <input
              type="file"
              className={
                "form-control" + (!errors.govIdFront ? "" : " danger-border")
              }
              {...register("govIdFront", { required: "This field is required" })} 
              accept="application/pdf, image/jpg,image/jpeg,image/png"
            />
            <p className="text-red">
              <ErrorMessage errors={errors} name="govIdFront" />
            </p>
          </div>
        </div>

        <div className="row mb-3">
          <div className="col-lg-6 form-group">
            <label>Government Issued Id Back (Not driving license)</label>
            <input
              type="file"
              className={
                "form-control" + (!errors.govIdBack ? "" : " danger-border")
              }
              {...register("govIdBack")} // Made non-mandatory
              accept="application/pdf, image/jpg,image/jpeg,image/png"
            />
            <p className="text-red">
              <ErrorMessage errors={errors} name="govIdBack" />
            </p>
          </div>
          <div className="col-lg-6 form-group">
            <label>Bank Statement Last 3 Months</label>
            <input
              type="file"
              className={
                "form-control" + (!errors.bankStatement ? "" : " danger-border")
              }
              {...register("bankStatement")} // Made non-mandatory
              accept="application/pdf, image/jpg,image/jpeg,image/png"
            />
            <p className="text-red">
              <ErrorMessage errors={errors} name="bankStatement" />
            </p>
          </div>
        </div>
        <StepNavigation />
      </form>
    </>
  );
};

export default Step2;
