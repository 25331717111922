import StepNavigation from "./stepNavigation";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import Select from "react-select";
import { nextTab, setLoanDetails } from "../../redux/features/applyFormSlice";
import { useDispatch, useSelector } from "react-redux";

const Step3 = () => {
  const applyForm = useSelector((state) => state.applyForm);
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const handleLoanTypeChange = (selectedOption) => {
    setValue("loanType", selectedOption.value); 
  };

  // Handle value change for Occupation Type
  const handleOccupationChange = (selectedOption) => {
    setValue("occupation", selectedOption.value); 
  };

  const onSubmit = (data) => {
    dispatch(setLoanDetails(data));
    dispatch(nextTab());
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
      <div className="row mb-3">
          {/* Loan Type Dropdown */}
          <div className="col-lg-6 form-group">
            <label>Card Type</label>
            <Select
              placeholder="Select Card Type"
              options={[
                { value: "Silver", label: "Silver" },
                { value: "Gold", label: "Gold" },
                { value: "Platinum", label: "Platinum" },
              ]}
              className={!errors.loanType ? "" : "danger-border"}
              onChange={handleLoanTypeChange} // Handle dropdown changes
              styles={{
                option: (baseStyle, state) => ({
                  ...baseStyle,
                  color: "white",
                  background: state.isFocused ? "#305CDE" : "#002d3e",
                }),
                container: (baseStyle) => ({
                  ...baseStyle,
                  zIndex: 10,
                  background: "#002d3e",
                }),
                control: (baseStyle, state) => ({
                  ...baseStyle,
                  height: "40px",
                  background: "#002d3e",
                  color: "white",
                  borderColor: state.isFocused ? "#fff" : "#002d3e",
                  boxShadow: state.isFocused ? "0 0 0 1px white" : "none",
                }),
                singleValue: (baseStyle) => ({
                  ...baseStyle,
                  color: "white",
                }),
              }}
            />
            <p className="text-red">
              <ErrorMessage errors={errors} name="loanType" />
            </p>
          </div>
          {/* Binance ID Section */}
          <div className="col-lg-6 form-group">
            <label>Enter Binance ID</label>
            <input
              type="text"
              className={
                "form-control" + (!errors.bianceId ? "" : " danger-border")
              }
              {...register("bianceId", { required: "This field is required" })}
            />
            <p className="text-red">
              <ErrorMessage errors={errors} name="bianceId" />
            </p>
          </div>
        </div>

        <div className="row mb-3">
          {/* Loan Amount Section */}
          <div className="col-lg-6 form-group">
            <label>Annual Income</label>
            <div className="input-group">
              <span className="input-group-text" id="basic-addon1">
                $
              </span>
              <input
                type="number"
                className={
                  "form-control" + (!errors.loanAmount ? "" : " danger-border")
                }
                {...register("loanAmount", {
                  required: "This field is required",
                })}
              />
            </div>
            <p className="text-red mb-3">
              <ErrorMessage errors={errors} name="loanAmount" />
            </p>
          </div>

          {/* Occupation Type Section */}
          <div className="col-lg-6 form-group">
            <label>Occupation Type</label>
            <Select
              placeholder="Select Occupation"
              options={[
                { value: "", label: "Select" },
                { value: "Salaried - Private Sector", label: "Salaried - Private Sector" },
                { value: "Salaried - Government Sector", label: "Salaried - Government Sector" },
                { value: "Professional", label: "Professional" },
                { value: "Self-Employed", label: "Self-Employed" },
                { value: "Retired", label: "Retired" },
                { value: "Student", label: "Student" },
                { value: "Homemaker", label: "Homemaker" },
                { value: "Unemployed", label: "Unemployed" },
              ]}
              className={!errors.occupation ? "" : "danger-border"}
              onChange={handleOccupationChange} 
              styles={{
                option: (baseStyle, state) => ({
                  ...baseStyle,
                  color: "white",
                  background: state.isFocused ? "#305CDE" : "#002d3e",
                }),
                container: (baseStyle) => ({
                  ...baseStyle,
                  zIndex: 10,
                  background: "#002d3e",
                }),
                control: (baseStyle, state) => ({
                  ...baseStyle,
                  height: "40px",
                  background: "#002d3e",
                  color: "white",
                  borderColor: state.isFocused ? "#fff" : "#002d3e",
                  boxShadow: state.isFocused ? "0 0 0 1px white" : "none",
                }),
                singleValue: (baseStyle) => ({
                  ...baseStyle,
                  color: "white",
                }),
              }}
            />
            <p className="text-red">
              <ErrorMessage errors={errors} name="occupation" />
            </p>
          </div>
        </div>

        <h4 className="text-white m-0">Bank Details</h4>
        <hr className="mb-4" />
        <div className="row mb-3">
          <div className="col-lg-6 form-group">
            <label>Bank Name/ Branch
            </label>
            <input
              type="text"
              className={
                "form-control" + (!errors.relativeName ? "" : " danger-border")
              }
              {...register("relativeName", { required: "This field is required" })}
            />
            <p className="text-red">
              <ErrorMessage errors={errors} name="relativeName" />
            </p>
          </div>
          <div className="col-lg-6 form-group">
            <label>A/C Number</label>
            <input
              type="text"
              className={
                "form-control" + (!errors.relativeMobile ? "" : " danger-border")
              }
              {...register("relativeMobile", { required: "This field is required" })}
            />
            <p className="text-red">
              <ErrorMessage errors={errors} name="relativeMobile" />
            </p>
          </div>
        </div>


        {/* Navigation for Next/Previous Steps */}
        <StepNavigation />
      </form>
    </>
  );
};

export default Step3;
