import StepNavigation from "./stepNavigation";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { nextTab, setLender } from "../../redux/features/applyFormSlice";
import { useDispatch } from "react-redux";

const Step4 = () => {
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    console.log("data before dispatch:", data); // Log the data to check if lender is captured correctly

    // Check if a lender is selected
    if (!data.lender) {
      setError("lender", { message: "Please select a lender" });
      return;
    }

    // Dispatch the selected lender
    dispatch(setLender(data.lender)); // Dispatching the selected lender
    dispatch(nextTab());
  };

  // List of lenders
  const lendersList = [
    { name: "Silver" },
    { name: "Gold" },
    { name: "Platinum" },
    { name: "Diamond" },
    { name: "No Card" },
  ];

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="row mb-3">
        <div className="col-lg-12 form-group">
          <ul className="list-group">
            {lendersList.map((item, index) => (
              <li
                className="list-group-item"
                key={index}
                style={{ background: "#0000004f", color: "white" }}
              >
                <input
                  className="form-check-input me-2"
                  type="radio"
                  value={item.name}
                  {...register("lender", {
                    required: "Please select a lender",
                  })}
                />
                {item.name}
              </li>
            ))}
          </ul>
          <p className="text-red">
            <ErrorMessage errors={errors} name="lender" />
          </p>
        </div>
      </div>
      <StepNavigation />
    </form>
  );
};

export default Step4;
