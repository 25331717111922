import { Link } from "react-router-dom";

const TermsConditionPage = () => {
  return (
    <>
      <div className="p-5">
        <h3 className="text-white text-center">Terms & Conditions</h3>
        <div style={{ textAlign: "justify" }}>
          <div className="text-left text-white">
            <h5>AmazanExpress.com</h5>
            <p>
              <strong>Terms of Use</strong>
              <br />
              AmazanExpress.com ("AmazanExpress" or "Site" or "we" or "our") is
              a trade name owned and managed by Amazan Technologies Pvt Ltd. It
              is a web portal that provides a platform to bring together people
              who are willing to borrow and people who are willing to lend.
              AmazanExpress is an attempt to build a close community that would
              share its resources with each other in all honesty and promote
              togetherness and growth of the community at an individual level
              and of the community as a whole. AmazanExpress’s endeavor is that
              an individual may come forward to support another individual in
              need. On this web portal, a visitor could browse and search for
              people who are willing to lend, borrow, or find details of service
              providers such as verification agencies, collection agents, and
              other service providers offering services to either lenders or
              borrowers. AmazanExpress merely facilitates the transaction of
              borrowing and lending between two such individuals. AmazanExpress
              neither lends nor borrows through this website. AmazanExpress is
              neither a financial institution, nor does it run a collective
              investment scheme, chit fund, or any other financial scheme.
            </p>
            <p>
              <strong>SITE:</strong> Site refers to AmazanExpress.com, which is
              owned and managed by Amazan Technologies Private Limited.
            </p>
            <p>
              <strong>YOU/YOUR:</strong> You/Your refers to a natural or legal
              person who is at least 18 years of age, legally able to enter into
              a contract in Morocco, and uses the services and products on
              AmazanExpress.com in accordance with the terms and conditions
              outlined here. This includes individuals whose membership fees are
              paid or in arrears.
            </p>
            <h4>Acceptance of Terms and Conditions</h4>
            <p>
              Every effort has been made to ensure the information contained on
              the Site is correct. However, AmazanExpress makes no warranty as
              to the accuracy, comprehensiveness, or correctness of any
              Service(s) or Product(s) on the Site, and all services and
              products are provided on an "as is" basis. By using this Site, you
              agree to these Terms and Conditions ("Terms and Conditions" or
              "T&C" or "Terms"). If you do not wish to agree to these Terms and
              Conditions, please refrain from using the Site.
            </p>
            <p>
              The Service(s) or Product(s) displayed on the Site is an
              "invitation to offer" to the End User. Your order for purchasing
              any Service(s) or Product(s) constitutes your "offer" subject to
              the terms and conditions listed below. AmazanExpress.com reserves
              the right to accept or reject your offer. If you have provided a
              valid email address, we will notify you by email as soon as
              possible to confirm receipt of your instruction and process your
              request accordingly.
            </p>
            <p>
              No act or omission by AmazanExpress prior to the actual dispatch
              of the Product(s) or rendition of services ordered will constitute
              acceptance of your offer. Similarly, no act or omission by
              AmazanExpress prior to the actual rendition of services shall
              constitute acceptance of your offer.
            </p>
            <p>
              Persons under 18 years of age are not permitted to use this Site.
              The Site is freely accessible to End Users; however, End Users
              must register with AmazanExpress.com and create a registration
              account before purchasing any Service(s) or Product(s).
            </p>
            <p>
              AmazanExpress reserves the right to modify these Terms and
              Conditions and our Privacy Policy without prior notification. We
              recommend reviewing these policies periodically to stay informed
              of any changes.
            </p>
            <ul>
              <li>
                Your privacy is important to us, and we will protect it as per
                our Privacy Policy.
              </li>
              <li>
                By purchasing a service or product, you consent to receive
                emails/SMS/phone calls regarding your account and promotional
                offers.
              </li>
              <li>
                You are required to create an account to purchase any services
                or products.
              </li>
              <li>
                If you have any questions about these Terms and Conditions,
                contact AmazanExpress at <a href="mailto:Support@AmazanExpress.com" style={{textDecoration:"underline"}}>
              Support@AmazanExpress.com
            </a>.
              </li>
            </ul>
            <br></br>
            <h6>
              To read the complete AmazanExpress Terms and Conditions, please
              see below:
            </h6>

            <h5>1. Acceptance Of Terms And Conditions</h5>
            <p>
              By using this Site, you agree to the Terms and Conditions that
              AmazanExpress has provided. If you do not wish to agree to these
              Terms and Conditions, please refrain from using the Site. In the
              Site, AmazanExpress provides End Users with access to a database
              of lenders, borrowers, and service providers in relation to debt
              services such as verification agencies and collection agencies.
              AmazanExpress is only a platform that brings together borrowers
              and lenders; it matches the needs of borrowers and lenders and
              provides links to service providers associated with borrowing and
              lending, such as verification agencies and collection agencies.
              AmazanExpress does not collect deposits from lenders or persons
              who want to earn interest by lending money. AmazanExpress supports
              lenders by verifying the whereabouts of the borrower on a
              best-effort basis.
            </p>
            <p>
              If these T&C conflict with any other document, the T&C will
              prevail for the purposes of using the Site. If you do not agree to
              be bound by this T&C and the Privacy Policy, you may not use the
              Site.
            </p>

            <h5>2. Description Of Products & Services</h5>
            <p>
              In the Site, AmazanExpress provides End Users with access to
              lenders, borrowers, and service providers, as well as partners who
              facilitate lending and provide credit reports for lenders and
              borrowers. By clicking "yes" in the options menu, you agree to
              receive regular updates via email, SMS, phone call, or any other
              means regarding our Services and Product offerings.
            </p>

            <h5>3. General</h5>
            <p>
              These terms and conditions apply to users of this Site. By using
              this Site (other than to read this T&C for the first time), the
              user agrees to comply with all of the terms and conditions hereof.
              The right to use this Site is personal to the user and is not
              transferable to any other person or entity. Users shall be
              responsible for protecting the confidentiality of their
              password(s), if any. Users acknowledge that, although the internet
              is often a secure environment, there may be interruptions in
              service or events beyond the control of AmazanExpress.
              AmazanExpress shall not be responsible for any data lost while
              transmitting information on the internet. While it is
              AmazanExpress’s objective to make the Site accessible 24 hours per
              day, 7 days per week, the Site may be unavailable from time to
              time for any reason, including routine maintenance. AmazanExpress
              reserves the right to change or discontinue any aspect or feature
              of the Site, including content, hours of availability, and
              equipment needed for access or use.
            </p>

            <h5>4. Modified Terms</h5>
            <p>
              AmazanExpress reserves the right at all times to discontinue or
              modify any of the Terms and Conditions as may be deemed necessary
              or desirable without prior notification to you. Changes may
              include, among other things, adding certain fees or charges. We
              suggest that you re-read this important notice containing our
              Terms and Conditions from time to time to stay informed of any
              changes. By continuing to use our Site after changes are made, you
              agree to the modified Terms and Conditions and Privacy Policy. Any
              changes will take effect immediately upon AmazanExpress posting
              the updated terms. Use of AmazanExpress after such notice will be
              deemed acceptance by the End User.
            </p>

            <h5>5. License And Site Access</h5>
            <p>
              AmazanExpress grants you a limited license to access and make
              personal use of the Site. This license does not include any
              downloading or copying of account information for the benefit of
              another vendor or any other third party; caching, unauthorized
              hypertext links to the Site and the framing of any Content
              available through the Site, uploading, posting, or transmitting
              any content that you do not have a right to make available (such
              as the intellectual property of another party); uploading,
              posting, or transmitting any material that contains software
              viruses or any other computer code, files or programs designed to
              interrupt, destroy or limit the functionality of any computer
              software or hardware or telecommunications equipment; any action
              that imposes or may impose (in AmazanExpress sole discretion) an
              unreasonable or disproportionately large load on AmazanExpress
              infrastructure; or any use of data mining, robots, or similar data
              gathering and extraction tools. You may not bypass any measures
              used by AmazanExpress to prevent or restrict access to the Site.
              Any unauthorized use by you shall terminate the permission or
              license granted to you by AmazanExpress. By using this Site and by
              accepting to agree to these terms and conditions you also agree
              that you shall not hack or otherwise hire some third party to hack
              the site or use any other unauthorized means without a written
              consent of AmazanExpress and access any third party information.
              You shall in such an event be liable of a criminal offence and
              AmazanExpress shall institute appropriate legal proceedings to
              claim damages. AmazanExpress also reserve rights to institute
              proceedings in accordance with the law relating to intellectual
              property rights violation or infringement available to it in case
              the misuse of this website is a person or an entity that belongs
              to out of Morocco. In any event, the jurisdiction to institute any
              protective proceedings shall vest with the AmazanExpress within
              the jurisdiction of Morocco.
            </p>
            <p>
              Our website users may experience difficulty in accessing website
              from 0300 am to 0330 am due to website updates. We apologize for
              the inconvenience caused to our Users.
            </p>
            <h5>6. AmazanExpress Usage Eligibility</h5>
            <p>
              The AmazanExpress.com Service is not available to persons under
              the age of 18 or of unsound mind or who are declared insolvent or
              to any Users suspended or removed from the system by AmazanExpress
              for any reason. Users may not have more than one active account.
              Additionally, Users are prohibited from selling, trading, or
              otherwise transferring their AmazanExpress.com account to another
              party. If you do not qualify, you may not use the
              AmazanExpress.com or the Site.
            </p>

            <h5>7. Your Account</h5>
            <p>
              In consideration of your use of the Site, you represent that you
              are of legal age to form a binding contract and are not a person
              barred from receiving services or goods under the laws as
              applicable in Morocco. Additionally, you are more than 18 years of
              age. You also agree to provide true, accurate, current and
              complete information about yourself as prompted by the Site's
              registration form. If you provide any information that is untrue,
              inaccurate, not current or incomplete (or becomes untrue,
              inaccurate, not current or incomplete), or AmazanExpress has
              reasonable grounds to suspect that such information is untrue,
              inaccurate, not current or incomplete, AmazanExpress has the right
              to suspend or terminate your account and refuse any and all
              current or future use of the Site (or any portion thereof). As
              AmazanExpress is a website that enables borrowers to find lenders
              and lenders to find borrowers, it considers provisions of wrong
              information on this web portal willingly an act that would
              constitute misrepresentation, fraud and cheating. If you use the
              Site, you are responsible for maintaining the confidentiality of
              your account and password, and for restricting access to your
              computer. You agree to accept responsibility for all activities
              that occur under your account or password. Because of this, we
              strongly recommend that you exit from your account at the end of
              each session. You agree to notify AmazanExpress immediately of any
              unauthorized use of your account or any other breach of security.
              AmazanExpress reserves the right to refuse service, terminate
              accounts, or remove or edit content in its sole discretion. In the
              event, AmazanExpress finds that the information shared by the User
              of the website is incorrect or that the User is in breach of terms
              or conditions of the website or User is in default of the lenders
              and borrowers agreement than in such case AmazanExpress may add
              the name and details of such person in the list of persons who are
              blacklisted from receiving funds or lending.
            </p>
            <h5>8. Equipment</h5>
            <p>
              User shall be responsible for obtaining and maintaining all
              telephone, computer hardware and other equipment needed for access
              to and use of this Site and all charges related thereto.
              AmazanExpress shall not be liable for any damages to the End
              User's equipment resulting from the use of this Site.
            </p>
            <h5>9. End User conduct</h5>
            <p>
              This Site and any individual sites or merchant-specific,
              city-specific, or state-specific sites now or hereinafter
              contained within or otherwise available through external
              hyperlinks with our Site are private property. All interactions on
              this Site and/or must comply with these Terms and Conditions.
              Although we welcome and encourage user interaction on our Site, we
              do insist and require that all End Users restrict any and all
              activity in connection with the use of this Site and to that which
              involves lawful purposes only. End User shall not post or transmit
              through this Site any material which violates or infringes in any
              way upon the rights of others, or any material which is unlawful,
              threatening, abusive, defamatory, invasive of privacy or publicity
              rights, vulgar, obscene, profane or otherwise objectionable, which
              encourages conduct that would constitute a criminal offense, give
              rise to civil liability or otherwise violate any law, or which,
              without AmazanExpress express prior, written approval, contains
              advertising or any solicitation with respect to Products or
              Services. Any conduct by an End User that in AmazanExpress
              exclusive discretion restricts or inhibits any other End User from
              using or enjoying this Site and/or any of is strictly prohibited.
              End User shall NOT use this Site or any of to advertise or perform
              any commercial, religious, political or noncommercial
              solicitation, including, but not limited to, the solicitation of
              users of this Site and/or to become users of other on- or offline
              services directly or indirectly competitive or potentially
              competitive with AmazanExpress.
            </p>
            <p>
              The foregoing provisions of this Section apply equally to and are
              for the benefit of AmazanExpress, its subsidiaries, affiliates and
              its third party content providers and licensors, and each shall
              have the right to assert and enforce such provisions directly or
              on its own behalf.
            </p>
            <h5>10. Monitoring</h5>
            <p>
              AmazanExpress shall have the right, but not the obligation, to
              monitor the content of the Site at all times, including any chat
              rooms and forums that may hereinafter be included as part of the
              Site, to determine compliance with this Agreement and any
              operating rules established by AmazanExpress, as well as to
              satisfy any applicable law, regulation or authorized government
              request. Without limiting the foregoing, AmazanExpress shall have
              the right to remove any material that AmazanExpress, in its sole
              discretion, finds to be in violation of the provisions hereof or
              otherwise objectionable.
            </p>
            <h5>11. License Grant</h5>
            <p>
              By posting communications on or through this Site, User shall be
              deemed to have granted to AmazanExpress a royalty-free, perpetual,
              irrevocable, non-exclusive license to use, reproduce, modify,
              publish, edit, translate, distribute, perform, and display the
              communication alone or as part of other works in any form, media,
              or technology whether now known or hereafter developed, and to
              sublicense such rights through multiple tiers of sub-licensees.
            </p>
            <h5>12. Personal Information</h5>
            <p>
              In order to avail of the Services from the Site, you will need to
              provide your details prior to get enlisted either as a borrower or
              as a lender. As an account holder you are required to provide
              AmazanExpress with accurate personal information. Provision of
              another person’s details as your own will not be deemed
              acceptable.
            </p>
            <p>
              Any personal information collected by AmazanExpress shall not be
              disclosed to any third party unrelated to the AmazanExpress
              business unless such a disclosure is authorized or required by
              law, or you have expressly consented to such a disclosure.
              However, by accepting the terms and conditions you grant
              AmazanExpress an unconditional right to share your personal
              information with AmazanExpress partners, affiliates, employees and
              agents for administrative purpose. Please also see the Privacy
              Policy in this regard.
            </p>
            <h5>13. Credit Card Information</h5>
            <p>We actually don’t need nor we demand for credit card detail.</p>
            <h5>14. Cancellation by AmazanExpress</h5>
            <p>
              Please note that there may be certain instruction to remit
              payments or seek rendition of services that AmazanExpress is
              unable to accept shall be cancel. We reserve the right, at our
              sole discretion, to refuse or cancel any order for any reason. We
              may also require additional verifications or information before
              accepting any instruction or registering you on are portal as a
              lender or a borrower. The borrower(s) and lender(s) are required
              to note that negative third party verification report(s) are
              crucial factors considered during the funding process. The funding
              may get rejected at any stage due to lack of information or
              verifiable information.
            </p>
            <h5>15. Fraudulent /Declined Transactions</h5>
            <p>
              AmazanExpress reserves the right to recover the cost of Service(s)
              and Product(s), collection charges and lawyers fees from persons
              using the Site fraudulently. AmazanExpress reserves the right to
              initiate legal proceedings against such persons for fraudulent use
              of the Site and any other unlawful acts or omissions in breach of
              these terms and conditions.
            </p>
            <h5>16. Limitation of Liability</h5>
            <p>
              AmazanExpress, its associates and technology partners make no
              representations or warranties about the accuracy, reliability,
              completeness, current-ness and/or timeliness of any content,
              information, software, text, graphics, links or communications
              provided on or through the use of the Site or that the operation
              of the Site will be error free and/or uninterrupted. Consequently,
              AmazanExpress assumes no liability whatsoever for any monetary or
              other damage suffered by you on account of the delay, failure,
              interruption, or corruption of any data or other information
              transmitted in connection with use of the Site; and/or any
              interruption or errors in the operation of the Site. You expressly
              understand and agree that AmazanExpress and its subsidiaries,
              affiliates, officers, employees, agents, partners and licensors
              shall not be liable to you for any direct, indirect, incidental,
              special, consequential or exemplary damages, including, but not
              limited to, damages for loss of profits, goodwill, use, data or
              other intangible losses (even if AmazanExpress has been advised of
              the possibility of such damages), resulting from use of the Site,
              sale and supply of Products content or any related goods and
              services.
            </p>
            <p>
              This limitation of liability section shall prevail over any
              conflicting or inconsistent provision contained in any of the
              documents comprising this Agreement. It is up to you to take
              precautions to ensure that whatever you select for your use is
              free of such items as viruses, worms other items of a destructive
              nature.
            </p>
            <p>
              The user further with immediate effect waive any right which had
              accrued to him from using the website to initiate any legal action
              against AmazanExpress. User also acknowledge that the credibility
              of a lender or a borrower as depicted upon the website is based on
              the information that is provided to AmazanExpress. In case there
              is a non-disclosure of information to AmazanExpress of the
              information, and the judgment of AmazanExpress is impaired and the
              ratings or credibility of a lender or a borrower is not found to
              be correct, AmazanExpress shall not be responsible for any claims
              or liabilities. AmazanExpress in any event is not responsible for
              any default in return of money that is borrowed from the lender
              and the lender shall have exclusive right to initiate proceedings
              against the defaulter borrower. AmazanExpress do provide the
              necessary information and support to the lender. However, lender
              shall not make any claim against AmazanExpress and shall conduct
              an independent due diligence of the borrower before lending it to
              him.
            </p>
            <h5>17. Indemnity</h5>
            <p>
              You agree to indemnify and hold AmazanExpress (and its officers,
              directors, agents, subsidiaries, joint ventures, and employees)
              harmless from any claim or demand, including reasonable attorneys’
              fees, or arising out of or related to your breach of this T&C, or
              your violation of any law or the rights of a third party including
              but not limited to breach of any warranties, representations or
              undertakings or in relation to the non-fulfillment of any of your
              obligations under this T&C or arising out of violation of any
              applicable laws, regulations including but not limited to
              Intellectual Property Rights, payment of statutory dues and taxes,
              claim of libel, defamation, violation of rights of privacy or
              publicity, loss of service by other subscribers and infringement
              of intellectual property or other rights. This clause shall
              survive the expiry or termination of this T&C.
            </p>
            <h5>18. Severability</h5>
            <p>
              If any of the Terms shall be deemed void, invalid or unenforceable
              for any reason, that condition shall be deemed several and shall
              not affect the enforceability of any remaining provision.
            </p>
            <h5>19. Customer Communication</h5>
            <p>
              AmazanExpress will communicate with you through any of the mode
              using email, SMS and telephone or all of them or any other mode.
              Account holders must provide a valid email address and contact
              telephone and/ or mobile number to AmazanExpress for this purpose.
              Please note AmazanExpress will not be able to contact the customer
              in case customer’s number is part of DNC (Do Not Call) list as
              generated by TRM (Telecom Regulatory of Morocco). In this case we
              request customers to call us on our call center number. When you
              use the Site or send emails to AmazanExpress, you are
              communicating with AmazanExpress electronically. You consent to
              receive communications from AmazanExpress electronically.
            </p>
            <p>
              From time to time, AmazanExpress will also send you marketing
              material. If you do not wish to receive such material, you may
              unsubscribe from our mailing list at any time by using the
              unsubscribe function in the electronic marketing communication or
              by contacting at{" "}
              <a
                href="mailto:Support@AmazanExpress.com"
                style={{ textDecoration: "underline" }}
              >
                Support@AmazanExpress.com
              </a>
            </p>
            <p>
              You agree that all agreements, notices, disclosures and other
              communications that we provide to you electronically satisfy any
              legal requirement that such communications be in writing.
            </p>
            <h5>20. Site-Provided Email and Postings</h5>
            <p>
              The Site may provide End Users with the ability to send email
              messages to other End Users and non-End Users and to post messages
              on the Site. AmazanExpress is under no obligation to review any
              messages; information or content ("Postings") posted on the Site
              by End Users and assumes no responsibility or liability relating
              to any such Postings.
            </p>
            <p>
              You understand and agree not to use any functionality provided by
              the Site to post content or initiate communications that contain:
            </p>
            <ol>
              <li>
                (i) Any unlawful, harmful, threatening, abusive, harassing,
                defamatory, vulgar, obscene, profane, hateful, racially,
                ethnically or otherwise objectionable material of any kind,
                including, but not limited to, any material which encourages
                conduct that would constitute a criminal offense, give rise to
                civil liability or otherwise violate any applicable local,
                state, national or international law.
              </li>
              <li>(ii) Advertisements or solicitations of any kind.</li>
              <li>
                (iii) Impersonate others or provide any kind of false
                information.
              </li>
              <li>
                (iv) Personal information such as messages which state phone
                numbers, social security numbers, account numbers, addresses, or
                employer references.
              </li>
              <li>
                (v) Messages by non-spokesperson employees of AmazanExpress
                purporting to speak on behalf of AmazanExpress or containing
                confidential information or expressing opinions concerning
                AmazanExpress.
              </li>
              <li>
                (vi) Messages that offer unauthorized downloads of any
                copyrighted or private information.
              </li>
              <li>(vii) Chain letters of any kind.</li>
              <li>
                (viii) Identical (or substantially similar) messages to multiple
                recipients advertising any product or service, expressing a
                political or other similar message, or any other type of
                unsolicited commercial message.
              </li>
            </ol>
            <p>This prohibition includes but is not limited to:</p>
            <ul>
              <li>
                (a) Using AmazanExpress.com invitations to send messages to
                people who don’t know you or who are unlikely to recognize you
                as a known contact;
              </li>
              <li>
                (b) Using AmazanExpress.com to connect to people who don’t know
                you and then sending unsolicited promotional messages to those
                direct connections without their permission; and
              </li>
              <li>
                (c) Sending messages to distribution lists, newsgroup aliases,
                or group aliases.
              </li>
            </ul>

            <h5>21. The Opt-out Practice</h5>
            <ul>
              <li>
                (a) If you are no longer interested in receiving e-mail
                announcements and other marketing information from us, or you
                want us to remove any personal information that we have
                collected about you, you can opt-out anytime by sending an
                e-mail about your request to{" "}
                <a
                  href="mailto:Support@AmazanExpress.com"
                  style={{ textDecoration: "underline" }}
                >
                  Support@AmazanExpress.com
                </a>
              </li>
              <li>
                (b) We use third-party service providers to serve ads on our
                behalf across the Internet and sometimes on this site. They may
                collect anonymous information about your visits to our website,
                and your interaction with our products and services. They may
                also use information about your visits to this and other
                websites to target advertisements for goods and services. This
                anonymous information is collected through the use of a pixel
                tag, which is industry standard technology used by most major
                websites.
              </li>
            </ul>
            <h5>22. Links</h5>
            <p>
              The Site or third parties may provide links to other World Wide
              Web sites or resources. Because AmazanExpress has no control over
              such sites and resources, you acknowledge and agree that
              AmazanExpress is not responsible for the availability of such
              external sites or resources, and does not endorse and is not
              responsible or liable for any content, advertising, products or
              other materials on or available from such sites or resources. You
              further acknowledge and agree that AmazanExpress shall not be
              responsible or liable, directly or indirectly, for any damage or
              loss caused or alleged to be caused by or in connection with use
              of or reliance on any such content, goods or services available on
              or through any such site or resource.
            </p>
            <h5>23. Access to Password Protected/Secure Areas</h5>
            <p>
              Access to and use of password protected and/or secure areas of the
              Site is restricted to authorized Users only. Unauthorized
              individuals attempting to access these areas of the Site may be
              subject to prosecution.
            </p>
            <h5>24. Acknowledgements</h5>
            <ol>
              <li>
                I. You acknowledge that AmazanExpress is not engaged in either
                the grant of loans or borrowing any money from any member of the
                website.
              </li>
              <li>
                II. You acknowledge that AmazanExpress manages this website and
                that neither AmazanExpress nor any of its subsidiaries or
                affiliates has any interest in either granting loans or
                borrowing money from a member registered on the website.
              </li>
              <li>
                III. You acknowledge that AmazanExpress is not at all
                responsible for any claim or damage in case either the person
                who has agreed to grant a loan has not granted the loan or the
                person who has agreed to repay the loan does not repay the loan.
              </li>
              <li>
                IV. You acknowledge that AmazanExpress, in no manner, warrants
                or guarantees the performance of a service provider that is
                providing services through the website of AmazanExpress.
              </li>
              <li>
                V. You acknowledge that AmazanExpress, in no manner, warrants
                and guarantees that the grantor of a loan or the recipient of a
                loan has provided all the information on this website which is
                true and correct, including their address, phone numbers, etc.
              </li>
              <li>
                VI. You acknowledge that it is your responsibility to verify the
                information about the person on the website, and AmazanExpress
                is in no manner liable if the information provided on this
                website is untrue or incorrect.
              </li>
              <li>
                VII. You acknowledge that AmazanExpress is in no manner
                responsible for any claim of money or damages in the event one
                person fails to either grant a loan or a person fails to repay
                the loan or misrepresents their financial status or commits
                fraud, cheating, or any other such illegal act.
              </li>
              <li>
                VIII. You acknowledge that you have taken expert advice from a
                legal consultant or any other expert, as required, about your
                status to lend or borrow on the site.
              </li>
              <li>
                IX. You acknowledge that you are aware of all your rights,
                duties, and the applicability of various laws, including but not
                limited to the Money Lending Act, Income Tax Act, or any other
                statute or law which may govern the lending and borrowing of
                money within your state of residence or from where you
                ordinarily conduct your business.
              </li>
            </ol>

            <h5>25.Disclaimer of Warranties</h5>
            <p>
              ALL DETAILS OF THE MEMBERS OF THIS AMAZANEXPRESS WEBSITE AND THEIR
              FINANCIAL STATUS, INCLUDED IN OR AVAILABLE THROUGH THIS SITE ARE
              PROVIDED FOR USE WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR
              IMPLIED, AMAZANEXPRESS, ITS SHAREHOLDERS, ITS HOLDING COMPANY, ITS
              SUBSIDIARIES, AND ITS LICENSORS DO NOT WARRANT THAT THE
              INFORMATION OR OTHER MATERIAL WHICH IS PLACED ON THE WEBSITE BY
              ANY PERSON IS CORRECT INCLUDING HIS FINANCIAL STATUS OR CAPABILITY
              OR HAS NOT APPROACHED THE OTHER USER OF THIS WEBSITE WITH A
              CRIMINAL INTENT TO EITHER CHEAT OR MISAPPROPRIATE OR DENY ANY
              OTHER USER OF ITS LEGITIMATE CLAIMS. BY UPLOADING ANY CONTENT,
              REPRESENTATION OR STATEMENT OF YOUR FINANCIAL STATUS OR YOUR PROOF
              OF RESIDENCE YOU REPRESENT AND WARRANT THAT YOU HAVE THE LAWFUL
              RIGHT TO REPRODUCE AND DISTRIBUTE SUCH CONTENT AND THAT THE
              CONTENT COMPLIES WITH ALL LAWS AND IS TRUE AND AUTHENTIC AND IS
              NOT IN ANY EVENT PLACED ON THE WEBSITE TO DEFRAUD OTHER USERS OR
              CREATE A FALSE IMPRESSION IN THEIR MINDS THAT YOU HAVE A SOUND
              FINANCIAL STATUS OR ARE REASONABLY COMMITTED TO DELIVER THE
              REPAYMENTS ON TIME. YOU ALSO REPRESENT THAT YOU HAVE NOT EVER BEEN
              ACCUSED TO ANY FRAUD, MISREPRESENTATION OR DEFAULT IN REPAYMENT OF
              ANY CLAIMS OR MONIES TO ANY THIRD PARTY WHETHER A FINANCIAL
              INSTITUTION OR NOT. YOU ALSO REPRESENT THAT THERE ARE NO
              OUTSTANDING DISPUTES IN CONNECTION WITH YOUR MORAL TURPITUDE OR
              FINANCIAL STATUS. YOU HEREBY REPRESENT AND WARRANT TO
              AMAZANEXPRESS THAT YOU ARE LEGALLY COMPETENT TO EXECUTE, BE BOUND
              BY AND ADHERE TO TERMS AND CONDITIONS OF TERMS AND CONDITIONS OF
              AMAZANEXPRESS. YOUR USE OF THIS SITE IS SOLELY AT YOUR RISK. YOU
              AGREE THAT AMAZANEXPRESS SHALL HAVE NO RESPONSIBILITY FOR ANY
              DAMAGES SUFFERED BY YOU IN CONNECTION WITH THIS SITE OR ANY
              CONTENT CONTAINED THEREIN OR ANY DAMAGE SUFFERED IN THE EVENT OF
              DEFAULT OR MISREPRESENTATION BY ANY OTHER USER OF THS WEBSITE.
            </p>
            <h5>26. Limitation of Liability</h5>
            <p>
              UNDER NO CIRCUMSTANCES SHALL AMAZANEXPRESS, ITS SUBSIDIARIES,
              EMPLOYEES, OFFICERS, AFFILIATES, DIRECTORS, AGENTS, SUPPLIERS,
              SHAREHOLDERS, NON EXECUTIVE DIRECTORS OR ITS LICENSORS INVOLVED IN
              CREATING, PRODUCING, TRANSMITTING OR DISTRIBUTING PRODUCTS OR
              SERVICES ON THIS SITE, GRANTING OR RECEIVING LOAN FROM A MEMBER
              ASSOCIATED WITH THE AMAZANEXPRESS WEBSITE BE LIABLE FOR ANY
              DIRECT, INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL, OR CONSEQUENTIAL
              DAMAGES THAT RESULT FROM EITHER MISREPRESENTATION, FRAUD,
              COERCION, BREACH OF TRUST, MISAPPROPRIATION, CHEATING OR FOR ANY
              OTHER SUCH REASON OF ITS END USERS OR OF THIRD PARTIES INCLUDING
              BUT NOT LIMITED TO LOSS OF PROFITS AND LOSS OF GOODWILL OR LOSS OF
              INTEREST.
            </p>
            <h5>27. Monitoring</h5>
            <p>
              In case it comes to your knowledge that any member of this website
              has committed default or fraud or misrepresents his financial
              status than you may inform AmazanExpress in writing and upon
              receipt of such information AmazanExpress shall call upon such
              third party to explain the allegations made in your Notice and
              upon receipt of an explanation from such third party AmazanExpress
              shall in its own discretion decide as to whether such a person
              should be prohibited from using the website.
            </p>
            <h5>28. Applicable Laws:</h5>
            <p>
              Your use of this Site and any Term and Conditions stated in this
              policy is subject to laws of Morocco.
            </p>
            <h5>29. Jurisdiction</h5>
            <p>
              Courts of morocco will have exclusive jurisdiction of dealing with
              all disputes arising from use of this site.
            </p>
            <h5>30. Trademarks</h5>
            <p>
              The trademarks, logos and service marks ("Marks") displayed on the
              Site are the property of AmazanExpress and other respective
              persons. End Users are prohibited from using any Marks for any
              purpose including, but not limited to use as meta-tags on other
              pages or sites on the World Wide Web without the written
              permission of AmazanExpress or such third party which may own the
              Marks. All information and content including any software programs
              available on or through the Site ("Content") is protected by
              copyright. End Users are prohibited from modifying, copying,
              distributing, transmitting, displaying, publishing, selling,
              licensing, creating derivative works or using any Content
              available on or through the Site for commercial or public
              purposes.
            </p>
            <h5>31. Changes to Terms and Conditions</h5>
            <p>
              AmazanExpress reserves the right to change these Terms and
              Conditions of its Service without informing you. You should check
              our Site for any updated Terms and Conditions.
            </p>
            <h5>32. Miscellaneous</h5>
            <p>
              If any of these conditions are deemed invalid, void, or for any
              reason unenforceable, the parties agree that the court should
              endeavor to give effect to the parties’ intentions as reflected in
              the provision, and the unenforceable condition shall be deemed
              severable and shall not affect the validity and enforceability of
              any remaining condition. From time to time AmazanExpress may offer
              special promotional offers which may or may not apply to your
              AmazanExpress account. To avail of such promotional offers, you
              agree to be bound by any additional terms and conditions for these
              special offers which are laid down by AmazanExpress. This T&C and
              the relationship between you and AmazanExpress will be governed by
              the laws as applicable in Morocco. Any dispute(s) arising out of
              this T&C will be handled in the competent courts of Morocco. The
              failure of AmazanExpress to act with respect to a breach by you or
              others does not waive its right to act with respect to subsequent
              or similar breaches. AmazanExpress does not guarantee it will take
              action against all breaches of this T&C. Except as otherwise,
              expressly provided in this T&C, there shall be no third-party
              beneficiaries to this T&C. This T&C constitutes the entire
              agreement between you and AmazanExpress and governs your use of
              the Site, superseding any prior agreements between you and
              AmazanExpress with respect to the Site.
            </p>
            <h5>33. Payment Methods</h5>
            <p>
              You can pay AmazanExpress using –payments services deployed by
              AmazanExpress from time to time. AmazanExpress has also deployed a
              system of electronic payment of fees, the details of which are
              available at<br></br>
              <a
                href="http://www.AmazanExpress.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                www.AmazanExpress.com
              </a>
              <br></br>
              All transactions are processed in cryptocurrency.<br></br>
              *********************************************
            </p>
            <p>
              <strong>34.</strong> AmazanExpress holds the complete right to de-activate any
              lender/borrower on its own accord if the lender/borrower is
              suspected or found to be from competition engaging or trying to
              engage in any malicious activity.
            </p>
            <h5>35. E-Sign or Virtual Signature</h5>
            <p>
              We have also introduced a system where the borrowers and lenders
              have the opportunity to electronically sign the Lender Agreement.
              Details about how to sign the Lender Agreement are available at{" "}
              <br></br>
              <a
                href="http://www.AmazanExpress.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                www.AmazanExpress.com
              </a>{" "}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default TermsConditionPage;
