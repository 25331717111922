import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { logout } from "../redux/features/authSlice";
import { applicationListApi, validateResponse } from "../network/apiCalls";
const ProfilePage = () => {
  const disptach = useDispatch();
  const navigate = useNavigate();
  const auth = useSelector((state) => state.auth);
  const [appList, setAppList] = useState([]);
  const fetchApplications = async () => {
    try {
      let response = await applicationListApi();
      if (response.status == 200 && response.data.status == 200) {
        setAppList(response.data.list);
      }
    } catch (err) {
      validateResponse(err.response, function () {
        disptach(logout());
        navigate("/");
      });
      toast.error(err.message);
      console.log(err);
    }
  };
  
  useEffect(() => {
    if (auth.userToken) {
      fetchApplications();
    }else{
      navigate("/");
    }
  }, []);

  return (
    <>
      <div className="container my-5">
        <div className="px-lg-5 pb-5">
          <p className="text-white d-flex flex-row align-items-center">
            <img src="/static/media/giphy.gif" className="hello-hand" />
            <span className="display-6">Hello! {auth.userInfo.fname} {auth.userInfo.lname}</span>
          </p>
          <hr className="mb-4" />
          <label className="text-white fs-4 mb-2">Application History</label>
          {appList.length == 0 ? (
            <p className="text-white text-center">No application records.</p>
          ) : (
            <div className="accordion" id="accordionPanelsStayOpenExample">
              {appList.length &&
                appList.map((item, index) => {
                  return <ApplicationItem application={item} key={index} />;
                })}
            </div>
          )}
        </div>
      </div>
    </>
  );
};
const PrintStatus = (props) =>{
  if(props.status == 0){
    return <label className="badge bg-warning me-2">Pending</label>
  }else if(props.status){
    return <label className="badge bg-success me-2">Approved</label>
  }else{
    return <label className="badge bg-danger me-2">Rejected</label>
  }
}
const ApplicationItem = (props) => {
  const data = props.application;
  return (
    <div className="accordion-item">
      <h2 className="accordion-header" id={"panelsStayOpen-headingTwo"+data.app_id}>
        <button
          className="accordion-button collapsed"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target={"#panelsStayOpen-collapseTwo"+data.app_id}
          aria-expanded="false"
          aria-controls={"#panelsStayOpen-collapseTwo"+data.app_id}
        >
          <div className="d-flex flex-row justify-content-between w-100">
            <span>
              #{data.app_id} - {data.first_name} {data.last_name}
            </span>{" "}
              <PrintStatus status={data.status}/>
          </div>
        </button>
      </h2>
      <div
        id={"panelsStayOpen-collapseTwo"+data.app_id}
        className="accordion-collapse collapse"
        aria-labelledby={"panelsStayOpen-headingTwo"+data.app_id}
      >
        <div className="accordion-body">
          {/* Personal */}
          <div className="text-white bg-secondary px-3 py-1 rounded">Personal Details</div>
          <table className="table">
            <tr><th>First name</th><td>{data.first_name}</td><th>Last name</th><td>{data.last_name}</td></tr>
            <tr><th>Email</th><td>{data.email}</td><th>Country</th><td>{data.country}</td></tr>
            <tr><th>Mobile</th><td>{data.phone_code} {data.mobile_no}</td><th>Address</th><td>{data.address}</td></tr>
            
          </table>
        </div>
      </div>
    </div>
  );
};

export default ProfilePage;
