import { Link } from "react-router-dom";

const PrivacyPolicyPage = () => {
  return (
    <div className=" p-5 ">
      <h3 className="text-white text-center">Privacy Policy</h3>
      <div style={{ textAlign: "justify" }}>
        <div className="text-left text-white">
            <h5>AmazanExpress.com</h5>
          <p>
            <strong>Privacy & Security Policy</strong>
          </p>
          <p>
            <strong>Effective Date: Effective from 1st September 2022 </strong>
          </p>
          <p>
            Amazan Technologies Private Limited ("AmazanExpress") values the
            users of our website (the "Website"). Your privacy and trust are
            very important to us. We recognize that you may be concerned about
            our collection, use, and disclosure of the personally identifiable
            information ("Personal Information") that we collect when you use
            the Website and the services offered on the Website
            ("AmazanExpress"). This Privacy Policy describes the information
            that we collect from you, how we collect this information, and what
            we do with it after we collect it. By using the Website you are
            accepting the practices described in this Privacy Policy. By using
            the Website you also give consent to the use of Your Personal
            Information by AmazanExpress in accordance with the terms set out in
            this Privacy Policy.
            <strong>
              {" "}
              If you have any questions or comments regarding this Privacy
              Policy, please contact us at <a href="mailto:Support@AmazanExpress.com" style={{textDecoration:"underline"}}>
              Support@AmazanExpress.com
            </a>
            </strong>{" "}
            If your complaint/dispute is not redressed within a period of one
            month, you may appeal to the Customer Education and Protection
            Department of Banque Commercial du Maroc.
          </p>

          <p>
            <strong>
              DOES AMAZANEXPRESS EVER MAKE CHANGES TO ITS PRIVACY POLICY?
            </strong>
          </p>
          <p>
            We may make changes to the Privacy Policy from time to time. We will
            notify you of any material changes by sending you an email, posting
            a notice on the home page of the Website, or posting a notice in
            your account when you first login after we have made changes. If you
            receive notification or a change in our Privacy Policy, you must
            review the new Privacy Policy carefully to make sure you understand
            our practices and procedures. You may not be able to receive notices
            from us if your cookies are not set to accept and/or are disabled.
          </p>

          <p>
            <strong>
              WHAT TYPES OF INFORMATION DOES AMAZANEXPRESS COLLECT FROM ME?
            </strong>
          </p>
          <p>
            <strong>Sensitive Personal Data/Information:</strong> We collect
            Sensitive Personal Data/Information that you provide to us, such as
            your name, mailing address, phone number, email address, credit card
            number, PAN number, TAN number, income tax returns, bank account
            number, passport details, driving license details, personal
            statements, reasons for seeking finance, income sources and
            financial information.
          </p>

          <p>
            <strong>Non-Personal Information:</strong> We also collect
            non-personal information from you, such as your browser type, the
            URL of the previous website you visited, your ISP, operating system,
            and your Internet protocol (IP) Address ("Non-Personal
            Information"). Non-Personal Information cannot be easily used to
            personally identify you.
          </p>

          <p>
            <strong>
              HOW AND WHEN DOES AMAZANEXPRESS COLLECT THIS INFORMATION?
            </strong>
          </p>
          <p>
            <strong>Providing Information to Us:</strong> We collect
            Sensitive/Personal Data/Information from you when you provide it to
            us. For example, if you purchase a product or service, or make an
            application for seeking a grant of loan from a borrower, we may
            collect your name, mailing address, telephone number, credit card
            number, PAN number, TAN number, income tax returns, bank account
            number, passport details, driving license details, personal
            statements, reasons for seeking finance, income sources, and email
            address. If you create either a Lender Account or a Borrower
            Account, we may collect your name, tax identification number,
            mailing address, email address, credit card number, PAN number, TAN
            number, income tax returns, bank account number, passport details,
            driving license details, personal statements, reasons for seeking
            finance, income sources, and other information that we request
            during the registration process, and any information that you
            provide on your AmazanExpress account. If you sign up to receive a
            newsletter, we will collect your email address.
          </p>

          <p>
            <strong>Communications With Us:</strong> If you communicate with us
            regarding the Website or AmazanExpress Services, we will collect any
            information that you provide to us in any such communication.
          </p>

          <p>
            <strong>Analytic and Reporting Technologies:</strong> Like the
            operators of most websites, we use analytic and reporting
            technologies to record Non-Personal Information such as Internet
            domain and host names, Internet protocol (IP) addresses, browser
            software, operating system types, clickstream patterns, and the
            dates and times that the Website and AmazanExpress Services are
            accessed. We also contract with several online partners to help
            manage, monitor, and optimize our Website and AmazanExpress Services
            and to help us measure the effectiveness of our advertising,
            communications, and how visitors use the Website. To do this, we may
            use web beacons and cookies.
          </p>

          <p>
            <strong>HOW DOES AMAZANEXPRESS USE MY INFORMATION?</strong>
          </p>
          <p>
            <strong>Personal Information:</strong> We use Personal Information
            primarily for our own internal purposes, such as providing,
            maintaining, evaluating, and improving the Website, fulfilling
            requests for information, and providing customer support. Your
            personal information shall also be shared with the other members of
            this website. Upon acceptance of these terms and conditions, you
            hereby grant an unequivocal and absolute consent to share your
            personal information (but not your Sensitive Personal Data /
            Information) with third parties. AmazanExpress is not required to
            seek your consent once again in respect of sharing your personal
            information with third parties. We would further share your personal
            information with marketing agencies and other businesses if we in
            our sole discretion understand that your information may be used to
            market the Website or a product or service. We may also make public,
            your personal information in relation to any testimonials and
            feedback that you may provide us with and you hereby grant us the
            right to make such testimonials and feedback public on any public
            platform that we may choose. The term Personal Information shall
            include your name, address, e-mail id and mobile phone number, your
            images and photos, if any, but it shall not include any data in
            relation to your financial status, income statements, earnings,
            source of earnings, personal identification numbers, passwords, bank
            account numbers.
          </p>

          <p>
            <strong>Non-Personal Information:</strong> We use Non-Personal
            Information to track the use of the Website and AmazanExpress
            Services and for other internal purposes, such as providing,
            maintaining, evaluating, and improving AmazanExpress Services and
            the Website.
          </p>

          <p>
            <strong>
              WHEN WILL AMAZANEXPRESS DISCLOSE MY INFORMATION TO THIRD PARTIES?
            </strong>
          </p>
          <p>
            <strong>Disclosure to Successors:</strong> We may disclose your
            Personal Information to any successor-in-interest of ours, such as a
            company that acquires us. In the event AmazanExpress goes through a
            business transition, such as a merger or acquisition by or with
            another company, or sale of all or a portion of our assets, your
            Personal Information will likely be among the assets transferred.
            You will be notified via email or by a notice on our Website of any
            such change in ownership or control of your Personal Information.
          </p>

          <p>
            <strong>Disclosure to Unaffiliated Third Parties:</strong> We shall
            keep your personal information and Sensitive Personal
            Data/Information private and confidential. We may disclose your
            Personal Information and/or Sensitive Personal Data/Information to
            prevent an emergency, to respond to legal requirements, to protect
            or enforce our rights and policies, to protect or enforce the rights
            of a third party, or as required or permitted by law (including,
            without limitation, to comply with a summon or court order).
          </p>

          <p>
            <strong>
              Disclosure to Third Party Service Providers and Online Partners:
            </strong>{" "}
            We may contract with various third parties who help us provide,
            maintain, and improve the Website and the AmazanExpress Services.
            For example, we use a third party to process payments made to us,
            and may subcontract production, fulfillment, analytics, reporting,
            or other operations. We also contract with several online partners
            to help manage, monitor, and optimize our Website and AmazanExpress
            Services and to help us measure the effectiveness of our
            advertising, communications, and how visitors use the Website. We
            will use commercially reasonable efforts to prevent such third
            parties from disclosing your Personal Information, except for the
            purpose of providing Services in question. We cannot guarantee that
            such third parties will not disclose your Personal Information. In
            no circumstances shall AmazanExpress disclose data in relation to
            your financial status, income statements, earnings, source of
            earnings, personal identification numbers, passwords, code numbers,
            bank account numbers, credit card numbers, expiry dates,
            relationship numbers, CVV numbers, encryptions, cookies, and other
            such electronic details.
          </p>

          <p>
            <strong>Disclosure of Non-Personal Information:</strong> We will
            disclose Non-Personal Information, in aggregate form, to potential
            strategic partners, advertisers, investors, customers, and others.
            You may opt out of the sharing of this information by mailing the
            grievance redressal officer. However, it cannot be easily used to
            identify you personally.
          </p>

          <p>
            <strong>Internet Advertising:</strong> We may use third-party
            service providers to serve ads on our behalf across the Internet and
            sometimes on the Website. They may collect anonymous information
            about your visits to our Website and your interaction with our
            products and services. They may also use information about your
            visits to this and other websites to target advertisements for goods
            and services. This anonymous information is collected through the
            use of a web beacon, which is industry-standard technology used by
            most major websites.
          </p>

          <p>
            <strong>
              Disclosure to Credit Rating Agencies & Banque Commercial du Maroc:
            </strong>{" "}
            To make an improvement in its business, AmazanExpress may share the
            personal information of a user with Credit Rating Agencies, and the
            information shall be shared with Credit Rating Agencies in the event
            Banque Commercial du Maroc issues guidelines or direction in this
            regard.
          </p>

          <p>
            <strong>WHAT ABOUT COOKIES AND WEB BEACONS?</strong>
          </p>
          <p>
            A cookie is a small file placed on the hard drive of your computer.
            Most websites use cookies. We use cookies to track your use of the
            Website and AmazanExpress, provide you with a more personalized user
            experience, and to allow you to log in and begin using AmazanExpress
            Services automatically when you visit the Website. A web beacon is
            an often-transparent graphic image, usually no larger than a 1x1
            pixel, that is placed on a web page or in an email that is used to
            monitor the behavior of the user visiting the Website or receiving
            the email. Cookies and web beacons used by AmazanExpress and our
            online partners are not linked to Personal Information. This Privacy
            Policy covers the use of cookies and web beacons by AmazanExpress
            and does not cover the use of cookies or web beacons by any other
            third party or advertiser.
          </p>

          <p>
            <strong>DOES AMAZANEXPRESS PROTECT MY PERSONAL INFORMATION?</strong>
          </p>
          <p>
            <strong>Personal Information:</strong> Whenever we obtain your
            Personal Information, we use commercially reasonable efforts to
            protect it from unauthorized access or disclosure. However, we are
            not insurers of the security of your Personal Information.
            Accordingly, we assume no liability for any disclosure of data due
            to errors in transmission, unauthorized third-party access, or other
            acts of third parties, or acts or omissions beyond our reasonable
            control.
          </p>

          <p>
            <strong>Website Content:</strong> The content that you store, post,
            or transmit on or through the Website, such as message board
            postings, storefront pages, and images on your Products, may be
            accessed by other users, including people that you do not know. We
            are not responsible for the actions of others. Therefore, you should
            use care in communicating with other users and only disclose your
            Personal Information to other users that you know to be trustworthy.
            You should not assume that your content will be kept private.
          </p>

          <p>
            <strong>
              HOW CAN I REVIEW AND MAKE CHANGES TO MY PERSONAL INFORMATION?
            </strong>
          </p>
          <p>
            You can obtain a copy of and request that we correct errors in your
            Personal Information by emailing us at{" "}
            <a href="mailto:Support@AmazanExpress.com"style={{textDecoration:"underline"}}>
              Support@AmazanExpress.com
            </a>
            . You may mark the mail to grievance officer Ms. Reechli Siths.
          </p>

          <p>
            For your protection, you will be required to provide proof of your
            identity to obtain a copy of your Personal Information. If your
            Personal Information changes or if you no longer want to use the
            AmazanExpress Services, you may correct, update, or deactivate your
            Personal Information and/or your account through the account
            management screen. If you would like to deactivate or terminate your
            account, you can also contact us at{" "}
            <a href="mailto:Support@AmazanExpress.com"style={{textDecoration:"underline"}}>
              Support@AmazanExpress.com
            </a>
            .
          </p>

          <p>
            <strong>Tell-A-Friend:</strong> If you choose to use our referral
            service to tell a friend about the Website or AmazanExpress
            Services, we will ask you for your friend's name and email address.
            We will automatically send your friend a one-time email inviting him
            or her to visit the Website. We store this information for the sole
            purpose of sending this one-time email and tracking the success of
            our referral program.
          </p>

          <p>
            <strong>Security:</strong> The security of your Personal Information
            is important to us. When you enter sensitive information such as a
            credit card number on our registration or order forms, we encrypt
            that information using secure socket layer technology (SSL). We
            follow generally accepted industry standards to protect the Personal
            Information submitted to us, both during transmission and once we
            receive it. However, no method of transmission over the Internet or
            method of electronic storage is 100% secure. Therefore, while we
            strive to use commercially acceptable means to protect your Personal
            Information, we cannot guarantee its absolute security.
          </p>

          <p>
            <strong>Internet-based Transfers:</strong> Given that the Internet
            is a global environment, using the Internet to collect and process
            Personal Information necessarily involves the transmission of data
            on an international basis. By providing AmazanExpress with your
            Personal Information, you acknowledge and agree that we may store
            your Personal Information and transfer it to and from our servers.
            We endeavor to protect all Personal Information collected through
            the Website in a safe, confidential, and secure manner.
          </p>
          <p>
            <strong>
              DOES THIS PRIVACY POLICY APPLY WHEN I ACCESS THIRD PARTY WEBSITES?
            </strong>
          </p>
          <p>
            {" "}
            You may be able to access third-party websites directly from the
            Website. This Privacy Policy does not apply when you access
            third-party websites. We cannot control how third parties may use
            Personal Information you disclose to them, so you should carefully
            review the privacy policy of any third-party website you visit
            before using it or disclosing your Personal Information to its
            provider.
          </p>
          <p>
            <strong>WHAT ABOUT CHILDREN'S PRIVACY?</strong>
          </p>
          <p>
            {" "}
            The AmazanExpress Services are intended for users ages 18 and older
            only. Accordingly, we will not knowingly collect or use any Personal
            Information from individuals that we know to be under the age of 18.
            In addition, we will delete any information in our database that we
            know originates from an individual under the age of 18.
          </p>
          <p>
            <strong>
              CAN I OPT-OUT FROM RECEIVING COMMUNICATIONS FROM AMAZANEXPRESS?
            </strong>
          </p>
          <p>
            {" "}
            We provide you the opportunity to opt-out of having your Personal
            Information used for certain purposes. For example, if you purchase
            a product/service but do not wish to receive any additional
            marketing material from us, you can indicate your preference on our
            order form.
          </p>

          <p>
            If you no longer wish to receive our newsletters and promotional
            communications, you may opt-out of receiving them by following the
            instructions included in each newsletter or communication or by
            emailing us at{" "}
            <a href="mailto:support@AmazanExpress.com" style={{textDecoration:"underline"}}>
              support@AmazanExpress.com
            </a>
            . We also offer you an opportunity to opt-out of certain
            communications through the account management screen. If you need
            assistance, you may contact us at{" "}
            <a href="mailto:support@AmazanExpress.com" style={{textDecoration:"underline"}}>
              support@AmazanExpress.com
            </a>
            .
          </p>

          <p>
            If we choose to disclose your Personal Information to a third party
            in a manner that is not set forth in this Privacy Policy, you will
            be notified so that you can make an informed choice about sharing
            your Personal Information with that third party.
          </p>

          <p>
            You can request corrections to your Personal Information by
            contacting us at{" "}
            <a href="mailto:Support@AmazanExpress.com" style={{textDecoration:"underline"}}>
              Support@AmazanExpress.com
            </a>
            . You may also deactivate or terminate your account by contacting us
            directly.
          </p>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicyPage;
